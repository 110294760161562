<template>
  <b-modal
    id="productSelectorModal"
    ref="productSelectorModal"
    title="Product selector"
    ok-only
    ok-title="Save product"
    size="lg"

    @ok="saveData"
  >
    <template #modal-footer="{ ok }">
      <b-button

        v-if="showDeleteButton"
        variant="flat-danger"
        @click="deleteData()"
      >
        Delete product
      </b-button>

      <b-button
        variant="primary"
        @click="ok()"
      >
        Save product
      </b-button>

    </template>
    <div v-if="productData && taxesData">
      <!-- form -->

      <status-alert
        :is-success="isSuccess"
        :error-data="errorData"
        :success-msg="successMsg"
      />
      <b-row>
        <b-col
          md="12"
          lg="12"
        >
          <validation-observer

            ref="formProduct"
          > <b-form
            class="p-2"
          >
            <validation-provider
              #default="{errors}"
              :name="$t('Product name')"
              rules="required"
            >
              <!-- Name -->
              <b-col cols="12">

                <b-form-group
                  label="Product name"
                  label-for="product-name"
                >

                  <vue-autosuggest
                    v-model="productData.name"

                    :suggestions="productSuggest.filteredProducts"
                    :input-props="productSuggest.inputProps"
                    :state="errors.length > 0 ? false:null"
                    :value="productData.name"
                    @selected="productSelected"
                    @input="productOnInputChanged"
                  >
                    <template slot-scope="{suggestion}">
                      <div class="d-flex align-items-center">

                        <div class="detail ml-50">
                          <b-card-text class="mb-0">
                            <h5>    {{ suggestion.item.name }}</h5>

                          </b-card-text>
                          <small class="text-muted">
                            {{ $user().currency.currency_code }} {{ $staticParams.formatCurrency(suggestion.item.unit_price) }} {{ suggestion.item.description }}
                          </small>
                        </div>
                      </div>
                    </template>
                  </vue-autosuggest>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-col>
            </validation-provider>
            <!--/ Name -->

            <!-- billing address -->
            <b-col cols="12">
              <b-form-group
                label="Item description"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="productData.description"
                  size="lg"
                  rows="1"
                  placeholder="Enter a item description"
                />
              </b-form-group>
            </b-col>
            <!--/ billing address-->
            <b-row>
              <b-col md="6">
                <!-- phone -->
                <validation-provider
                  #default="{errors}"
                  :name="$t('Quantity')"
                  rules="required"
                >

                  <b-col cols="12">

                    <b-form-group
                      label="Quantity"
                      label-for="quantity"
                    >

                      <b-form-input
                        id="quantity"
                        v-model="productData.quantity"
                        size="lg"

                        :state="errors.length > 0 ? false:null"
                      />

                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                  </b-col>
                </validation-provider>
                <!--/ phone-->
              </b-col>
              <b-col>
                <!-- Phone -->
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Unit price')"
                  rules="required"
                >
                  <b-col cols="12">
                    <b-form-group
                      label="Unit Price"
                      label-for="price"
                    >
                      <cleave
                        id="price"
                        v-model="productData.unit_price"
                        size="lg"
                        class="form-control-lg form-control"
                        :raw="true"
                        :options="currencyClaveOptions"
                        placeholder="Enter unit price..."
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                  </b-col>
                </validation-provider>
                <!--/ Phone -->
              </b-col>
            </b-row>

            <b-col
              v-if="true==false"
              cols="12"
            >
              <b-form-group
                label="Unit Type"
                label-for="unit-type"
              >
                <v-select
                  v-model="productData.unit_type_id"
                  class="lg-input"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.state.app.globalParams.unitTypes"
                  :reduce="val => val.value"
                  autocomplete="chrome-off"
                  :clearable="false"
                  input-id="unit-id"
                />
              </b-form-group>
            </b-col>

            <b-col md="12 ">
              Taxes
              <hr>
              <div
                v-for="tax,index in taxratesOptionsData"
                :key="index"
              >
                <b-row>
                  <b-col
                    md="6"
                    class="d-flex justify-content-between justify-content align-items-center "
                  >
                    <b-form-checkbox
                      v-model="checkedTaxesData"
                      :value="tax.tax_id"
                      class="checkbox-lg"
                    >
                      <h5 class="font-weight-bolder">
                        {{ tax.tax_name }}
                      </h5>
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="d-flex justify-content-between justify-content align-items-center ">
                    <b-input-group
                      size="lg"
                      prepend="%"
                    >

                      <b-form-select
                        v-model="tax.selected_rate"
                        :options="tax.tax_rates"
                        value-field="rate"
                        :text-field="'rate'"
                        size="lg"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr>
              </div>
            </b-col>
            <b-col md="12">

              <div>
                <b-row>
                  <b-col
                    md="4"
                    class="d-flex justify-content-between justify-content align-items-center "
                  >
                    <b-form-checkbox
                      v-model="checkedDiscountData.enable"
                      :value="true"
                      :unchecked-value="false"
                      class="checkbox-lg"
                    >
                      <h5 class="font-weight-bolder">
                        Discount
                      </h5>
                    </b-form-checkbox>

                  </b-col>
                  <b-col class="d-flex justify-content-between justify-content align-items-center ">
                    <b-button-group size="lg">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :disabled="!checkedDiscountData.enable"
                        :variant="checkedDiscountData.type==1?'dark':'outline-dark'"
                        @click="checkedDiscountData.type=1"
                      >
                        %
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :disabled="!checkedDiscountData.enable"
                        :variant="checkedDiscountData.type==2?'dark':'outline-dark'"
                        @click="checkedDiscountData.type=2"
                      >
                        {{ currency }}
                      </b-button>

                    </b-button-group>
                  </b-col>
                  <b-col class="d-flex justify-content-between justify-content align-items-center ">

                    <b-input-group
                      size="lg"
                    >

                      <cleave
                        id="price"
                        v-model="checkedDiscountData.discount"
                        :disabled="!checkedDiscountData.enable"
                        size="lg"
                        class="form-control-lg form-control"
                        :raw="true"
                        :options="discountClaveOptions"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr>
              </div>
            </b-col>

          </b-form>
          </validation-observer>
        </b-col>

      </b-row>

    </div>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BFormTextarea, BFormInvalidFeedback, BCardText, BModal, BFormCheckbox, BFormSelect, BInputGroup, BButtonGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import { VueAutosuggest } from 'vue-autosuggest'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import productStoreModule from '../product/productStoreModule'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BRow,
    BFormSelect,
    BCol,
    Cleave,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormCheckbox,
    VueAutosuggest,
    BCardText,
    BModal,
    BButtonGroup,
    BButton,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    taxesData: {
      type: Array,
      default: null,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currencyClaveOptions: null,
      discountClaveOptions: null,
      showDeleteButton: true,
      productSuggest: {
        limit: 10,
        filteredProducts: [],
        inputProps: {
          id: 'product_id',
          class: 'form-control',
          placeholder: 'Enter a product name...',
          disabled: false,

        },

      },
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Product Updated',
      errorData: null,
      checkedTaxesData: [],
      selectedProductIndex: -1,
      taxratesOptionsData: [],
      checkedDiscountData: {
        discount: 0,
        type: 1, // 1 is rate, 2 is price,
        enable: false,
      },
      blankProductData: {
        id: 0,
        code: '',
        desription: '',
        name: '',
        quantity: 1,
        unit_price: 0,
        unit_type: 'Quantity',
        unit_type_id: 4,
        discount: null,
        withholding_tax_applies: false,
        applied_taxes: [],
      },
      productData: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  watch: {

    checkedDiscountData: {
      deep: true,
      handler(val) {
        if (val.enable) {
          this.productData.discount = val
        }
        if (val.type === 1) {
          this.discountClaveOptions.prefix = '% '
        } else {
          this.discountClaveOptions.prefix = `${this.currency} `
        }
      },
    },
    taxesData: {
      deep: true,
      handler(val) {
      //  alert(1)
        if (val != null) {
          this.initTaxes()
        }
      },
    },
    taxratesOptionsData() {
      this.handleTaxes()
    },
    checkedTaxesData() {
      this.handleTaxes()
    },
  },
  created() {
    this.resetForm()

    this.refreshGlobalParams()
    this.currencyClaveOptions = this.$staticParams.currencyClaveOptions()
    this.currencyClaveOptions.prefix = `${this.currency} `

    this.discountClaveOptions = this.$staticParams.currencyClaveOptions()
    this.discountClaveOptions.prefix = `${this.currency} `
    this.productOnInputChanged('')
    // this.initTaxes()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleTaxes() {
      this.productData.applied_taxes = []
      this.checkedTaxesData.forEach(val => {
        const selectedTax = this.taxratesOptionsData.filter(filt => filt.tax_id === val)[0]
        if (selectedTax) {
          this.productData.applied_taxes.push({ tax_rate: selectedTax.selected_rate, tax_name: selectedTax.tax_name, tax_id: selectedTax.tax_id })
        }
      })
    },
    initTaxes() {
      if (this.taxesData) {
        this.taxratesOptionsData = []

        this.taxesData.forEach(val => {
          const includedTax = this.productData.applied_taxes.filter(filt => filt.id === val.id)
          const defaultRate = val.tax_rates.filter(tf => tf.is_default)
          const taxOption = {
            tax_name: val.tax_name,
            selected_rate: 0,
            tax_rates: val.tax_rates,
            tax_id: val.id,
          }

          if (defaultRate.length > 0) {
            taxOption.selected_rate = defaultRate[0].rate
          }
          if (includedTax.length > 0) {
            taxOption.selected_rate = includedTax.rate
          }

          this.taxratesOptionsData.push(taxOption)
        })
      }
    },
    refreshGlobalParams() {
      store
        .dispatch('app/refreshGlobalParams')
    },
    showEditor(productParam, index) {
      this.selectedProductIndex = index
      if (productParam) {
      //  this.taxesData = { tax_data: this.taxesData }
        this.checkedTaxesData = []
        this.initTaxes()
        this.showDeleteButton = true
        this.productData = JSON.parse(JSON.stringify(productParam))

        this.productSuggest.inputProps.disabled = true
        productParam.applied_taxes.forEach(val => {
          this.checkedTaxesData.push(val.tax_id)
        })

        if (this.productData.discount == null) {
          this.checkedDiscountData = {
            discount: 0,
            enable: false,
            type: 1,

          }
        } else {
          this.checkedDiscountData = this.productData.discount
        }

      //  this.handleTaxes()
      } else {
        this.showDeleteButton = false
        this.resetForm()
        this.productSuggest.inputProps.disabled = false
        this.initTaxes()
        this.checkedTaxesData = []
          this.taxesData.forEach(val => {
          if (val.apply_to_all_items === 1) {
             this.checkedTaxesData.push(val.id)
          }
        })
      //  this.selectedTaxes = []
      }
      this.errorData = null
      this.$refs.productSelectorModal.show()
    },
    closeEditor() {
      this.$refs.productSelectorModal.hide()
      this.resetForm()
    },
    productSelected(option) {
      this.productData.id = option.item.id
      this.productData.name = option.item.name
      this.productData.description = option.item.description
      this.productData.unit_type_id = option.item.unit_id
      this.productData.unit_price = option.item.price

      this.checkedTaxesData = []
      if (option.item.enable_taxes) {
        this.taxratesOptionsData.forEach(val => {
          this.checkedTaxesData.push(val.tax_id)
        })
      } else {
        this.checkedTaxesData = []
      }
    },
    productOnInputChanged(text) {
      if (text === undefined) {
        return
      }
      store.dispatch('app/suggestProducts', { term: text })
        .then(response => {
          this.productSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
    cancel() {
      this.$emit('closed')
    },
    deleteData() {
      this.$emit('productDeleted', { product: this.productData, index: this.selectedProductIndex })
    },
    saveData(modalEvt) {
      modalEvt.preventDefault()
      this.$refs.formProduct.validate().then(success => {
        if (success) {
          this.productData.unit_type = this.$store.state.app.globalParams.unitTypes.filter(u => u.value === this.productData.unit_type_id)[0].label
          if (this.productData.id === 0) {
            store
              .dispatch('app-product/addProduct', {
                name: this.productData.name, description: this.productData.description, unit_id: this.productData.unit_type_id, price: this.productData.unit_price, cost: 0, enable_taxes: true,
              })
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.productData.id = response.data.data.id
                this.errorData = null
                this.$emit('productSelected', { product: this.productData, index: this.selectedProductIndex })
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.errorData = error.response.data
                }
              })
          } else {
            this.$emit('productSelected', { product: this.productData, index: this.selectedProductIndex })
          }
        }
      })
    },
    resetForm() {
      this.productData = JSON.parse(JSON.stringify(this.blankProductData))
      // this.checkedTaxesData = []
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-product'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
