var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"clientSelectorModal",attrs:{"id":"clientSelectorModal","title":"Client selector","ok-only":"","ok-title":"Save client","size":"lg"},on:{"ok":_vm.saveData}},[(_vm.clientData)?_c('div',[_c('status-alert',{attrs:{"is-success":_vm.isSuccess,"error-data":_vm.errorData,"success-msg":_vm.successMsg}}),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('validation-observer',{ref:"formClient"},[_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":_vm.$t('Client/Company Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Client/Company Name","label-for":"client-name","label-cols-md":"4"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.clientSuggest.filteredClients,"input-props":_vm.clientSuggest.inputProps,"state":errors.length > 0 ? false:null,"value":_vm.clientData.name},on:{"selected":_vm.clientSelected,"input":_vm.clientOnInputChanged},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"detail ml-50"},[_c('b-card-text',{staticClass:"mb-0"},[_c('h5',[_vm._v(" "+_vm._s(suggestion.item.name))])]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(suggestion.item.email)+" ")])],1)])]}}],null,true),model:{value:(_vm.clientData.name),callback:function ($$v) {_vm.$set(_vm.clientData, "name", $$v)},expression:"clientData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,2778900886)}),_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"email","size":"lg","placeholder":"Enter a email...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.email),callback:function ($$v) {_vm.$set(_vm.clientData, "email", $$v)},expression:"clientData.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,2206007749)}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Billing address","label-for":"billing-address","label-cols-md":"4"}},[_c('b-form-textarea',{attrs:{"id":"billing-address","size":"lg","rows":"1","placeholder":"Enter a description"},model:{value:(_vm.clientData.address),callback:function ($$v) {_vm.$set(_vm.clientData, "address", $$v)},expression:"clientData.address"}})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"phone","type":"tel","size":"lg","placeholder":"Enter a phone...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.phone),callback:function ($$v) {_vm.$set(_vm.clientData, "phone", $$v)},expression:"clientData.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,4286336838)}),_c('validation-provider',{attrs:{"name":_vm.$t('Mobile'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile","label-for":"mobile","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"mobile","size":"lg","placeholder":"Enter a mobile number...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.mobile),callback:function ($$v) {_vm.$set(_vm.clientData, "mobile", $$v)},expression:"clientData.mobile"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,906058154)}),_c('validation-provider',{attrs:{"name":_vm.$t('Tax number'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tax number","label-for":"tax-number","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"tax-number","size":"lg","placeholder":"Enter a tax number...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.tax_number),callback:function ($$v) {_vm.$set(_vm.clientData, "tax_number", $$v)},expression:"clientData.tax_number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,3822429496)})],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }