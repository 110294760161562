<template>
  <b-modal
    id="refCheckModal"
    ref="refCheckModal"
    size="lg"
    cancel-title="Skip"
    ok-title="Show invoice designer"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <b-row>
      <b-col>
        <h2>Would you like to browse the current invoice design?</h2>
        <span> You can replace the existing invoice template with different templates, and customize the invoice content as you wish.</span>

      </b-col>
      <b-col>
        <b-row class="d-flex justify-content-center">

          <b-img
            fluid
            rounded
            :src="require('./assets/invoice-preview.jpg')"
          />
        </b-row>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {
  BModal, BCol, BRow, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCol,
    BRow,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    handleOk() {
      this.$router.push({ name: 'settings-invoice-design' })
    },
    handleCancel() {
      this.$userHandler.setUsageStep('invoice_design_checked')
    },
    show() {
      this.$refs.refCheckModal.show()
    },
    close() {
      this.$refs.refCheckModal.hide()
    },

  },

}
</script>
